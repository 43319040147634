import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import {
  DsEquipmentIconModule,
  IsEqupmentIconPipe,
} from '@design-system/component/equipment-icon';
import { TranslateModule } from '@ngx-translate/core';
import { DsFilterInputComponent } from './filter-input.component';
import { ShowClearButtonPipe } from './show-clear-button.pipe';
import { IsPdIconPipeModule } from '@shared-lib/is-pd-icon-pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    PdLetDirectiveModule,
    MatCheckboxModule,
    MatInputModule,
    DsEquipmentIconModule,
    DsSpacingModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    IsPdIconPipeModule,
    IsEqupmentIconPipe,
  ],
  declarations: [DsFilterInputComponent, ShowClearButtonPipe],
  exports: [DsFilterInputComponent],
})
export class DsFilterInputModule {}
